import React, { FC } from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

const FluentHeartFilled: FC<{ fillColor?: string } & SvgProps> = ({
  fillColor = '#212121',
  ...props
}) => (
  <Svg
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <Path
      d='M12.82 5.58l-.82.822-.824-.824a5.375 5.375 0 10-7.601 7.602l7.895 7.895a.75.75 0 001.06 0l7.902-7.897a5.376 5.376 0 00-.001-7.599 5.38 5.38 0 00-7.611 0z'
      fill={fillColor}
    />
  </Svg>
)
export default FluentHeartFilled
